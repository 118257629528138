import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Video } from '../../video';
import { EmbedVideoService } from 'ngx-embed-video/dist';

@Component({
  selector: 'app-full-screen-modal',
  templateUrl: './full-screen-modal.component.html',
  styleUrls: ['./full-screen-modal.component.scss']
})
export class FullScreenModalComponent implements OnInit {

  @Input() video: Video;

  videoPlayer = '';

  constructor(public activeModal: NgbActiveModal, private embedVideoService: EmbedVideoService) { }

  ngOnInit() {
    this.videoPlayer = this.embedVideoService.embed_youtube(
      this.video.url,
      {
        query: {
          portrait: 0,
          color: '333',
          autoplay: 0,
          iv_load_policy: 3,
          rel: 0,
          enablejsapi: 1,
          controls: 1,
          playsinline: 1,
          autohide: 1,
          showinfo: 1,
          fs: 1,
          loop: 0,
          modestbranding: 1,
          origin: 'https://maki.petkovic.net;'
        },
        attr: {
          id: this.video.url.toLowerCase().replace(/\s/g, '_'),
          width: '100%',
          height: '100%',
          class: 'card-img-top',
          allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        }
      }
    );
  }
}
