import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { YoutubeVideosComponent } from './youtube-videos/youtube-videos.component';
import { VideoComponent } from './youtube-videos/video/video.component';
import { YoutubeVideoGroupsComponent } from './youtube-video-groups/youtube-video-groups.component';
import { VideoGroupComponent } from './youtube-video-groups/video-group/video-group.component';
import { HttpClientModule } from '@angular/common/http';
import { EmbedVideo } from 'ngx-embed-video/dist';
import { FullScreenModalComponent } from './youtube-videos/video/full-screen-modal/full-screen-modal.component';
import { YoutubeVideoPlayComponent } from './youtube-video-play/youtube-video-play.component';

@NgModule({
  declarations: [
    AppComponent,
    YoutubeVideosComponent,
    VideoComponent,
    YoutubeVideoGroupsComponent,
    VideoGroupComponent,
    FullScreenModalComponent,
    YoutubeVideoPlayComponent
  ],
  entryComponents: [
    FullScreenModalComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    EmbedVideo
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
