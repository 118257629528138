import { Injectable } from '@angular/core';
import { VideoGroup } from './video-group';
import {
  Observable,
  of
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetVideosService {

  videoGroups: Array<VideoGroup> = [];

  constructor(private httpClient: HttpClient) {
  }

  getVideoGroups(): Observable<Array<VideoGroup>> {
    return this.httpClient.get<Array<VideoGroup>>(environment.videoGroupsUrl);
  }
}
