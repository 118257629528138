import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { VideoGroup } from '../../youtube-videos/video-group';

@Component({
  selector: 'app-video-group',
  templateUrl: './video-group.component.html',
  styleUrls: ['./video-group.component.scss']
})
export class VideoGroupComponent implements OnInit {

  constructor() { }

  @Input()
  videoGroup: VideoGroup = null;

  ngOnInit() {
  }
}
