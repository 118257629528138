import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmbedVideoService } from 'ngx-embed-video/dist';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-youtube-video-play',
  templateUrl: './youtube-video-play.component.html',
  styleUrls: ['./youtube-video-play.component.scss']
})
export class YoutubeVideoPlayComponent implements OnInit {

  videoId = '';
  videoElement = null;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private embedVideoService: EmbedVideoService) { }

  ngOnInit() {
    this.videoId = this.route.snapshot.paramMap.get('videoId');
    this.videoElement = this.sanitizer.bypassSecurityTrustHtml(
      '<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' +
      this.videoId +
    '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
    // this.videoElement = this.embedVideoService.embed_youtube(
    //   this.videoId,
    //   {
    //     query: {
    //       portrait: 0,
    //       color: '333',
    //       autoplay: 0,
    //       iv_load_policy: 3,
    //       rel: 0,
    //       enablejsapi: 1,
    //       controls: 1,
    //       playsinline: 1,
    //       autohide: 1,
    //       showinfo: 1,
    //       fs: 1,
    //       loop: 0,
    //       modestbranding: 1,
    //       origin: 'https://maki.petkovic.net;',
    //     },
    //     attr: {
    //       id: this.videoId.toLowerCase().replace(/\s/g, '_'),
    //       width: '100%',
    //       height: '100%',
    //       class: 'card-img-top',
    //       allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
    //     }
    //   }
    // );
  }

}
