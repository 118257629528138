import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Video } from '../video';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FullScreenModalComponent } from './full-screen-modal/full-screen-modal.component';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  constructor(private modalService: NgbModal) {
  }

  @Input()
  video: Video;

  videoImage = '';

  ngOnInit() {
    this.videoImage = 'https://img.youtube.com/vi/'
      + (this.video.image ? this.video.image : this.video.url)
      + '/hqdefault.jpg';
  }

  maximizeVideo(video: Video) {
    const modalRef = this.modalService.open(
      FullScreenModalComponent,
      {
        size: 'xl',
        centered: true,
        windowClass : 'maki-youtube-modal'
      });
    modalRef.componentInstance.video = video;
  }
}
