import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {YoutubeVideosComponent} from './youtube-videos/youtube-videos.component';
import { YoutubeVideoGroupsComponent } from './youtube-video-groups/youtube-video-groups.component';
import { YoutubeVideoPlayComponent } from './youtube-video-play/youtube-video-play.component';


const routes: Routes = [
  { path: '', component: YoutubeVideoGroupsComponent},
  { path: 'play/:videoId', component: YoutubeVideoPlayComponent},
  { path: ':id', component: YoutubeVideosComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
