import { Component, OnInit } from '@angular/core';
import { GetVideosService } from '../youtube-videos/get-videos.service';
import { VideoGroup } from '../youtube-videos/video-group';

@Component({
  selector: 'app-youtube-video-groups',
  templateUrl: './youtube-video-groups.component.html',
  styleUrls: ['./youtube-video-groups.component.scss']
})
export class YoutubeVideoGroupsComponent implements OnInit {

  videoGroups: Array<VideoGroup> = [];

  constructor(private getVideosService: GetVideosService) { }

  ngOnInit() {
    this.getVideosService.getVideoGroups().subscribe(
      (response: Array<VideoGroup>) => {
        console.log(response);
        this.videoGroups = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
